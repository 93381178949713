
.login{
    background-color: aqua;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginContainer{
    display: flex;
    width: 500px;
    height: 500px;
    background-color: aliceblue;
    border-radius: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

}
.mtittle{
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 12px;
}
.tittle{
    color: rgb(127, 123, 123);
    margin-bottom: 15px;
    font-size: 24px;
}
.loginTittle {
    display: flex;
    align-items: center;
    flex-direction: column;
}
form{
     display: flex;
    /* width: 500px;
    height: 500px; */
    background-color: aliceblue;
    /* border-radius: 20px; */
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.loginContainer input{
    height: 30px;
    width:300px;
    padding: 10px 0 10px 8px;
    border: none;
    border-bottom: 1px solid gray;
    outline: rgb(167, 106, 15);

}
.loginContainer button{
    height: 50px;
    width:300px;
    border-color: rgb(0, 255, 255);
    background-color: rgb(91, 91, 236);
    color: azure;
    cursor: pointer;
    font-size: large;   
}