/* @mixin mobile{
    @media screen and (max-width : 480px) {
        @content
    }
}
@mixin tablet{
    @media screen and (max-width : 768px) {
        @content
    }
}
@mixin labtop{
    @media screen and (max-width : 1200px) {
        @content
    }
} */




.home{
    background-color: aqua;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container{
    width: 80%;
    height: 80vh;
    border-radius: 10px;
    overflow: hidden;
    background-color: aliceblue;
    display: flex;
}

.sidebar{
    flex: 2;
    background-color: rgba(90, 46, 143, 0.932);
    /* border-radius: 10px; */
}
.chat{
    flex: 7;
}



 /* home page  */
 /* navbar */
.navbar{
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid gainsboro;
    padding: 10px 0 20px;
    background-color: rgb(71, 6, 131);
}
.navbar .logo{
    font-size: 20px;
    font-weight: 600;
    flex: 2;
    color: white;
    /* @include tablet {
        display: none;
    } */
}
.navbar .user{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    flex: 3;
}
.navbar img{
    width: 24px;
    height: 24px;
    border-radius: 50%;
    object-fit: cover;
}

.navbar button{
    background-color: aqua;
    border-radius: 5px;
    height: 25px;
    font-size: 10px;
    cursor: pointer;
    /* @include tablet { */
       /* position: absolute;
       bottom: 10px;
    } */

}

/* Search page */
.search{
    display: flex;
    width: 100%;
    border-bottom: 2px solid gray;
    padding-bottom: 5px;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}
.searchForm{
    display: flex;

    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.searchForm input{
    width: 90%;
    background-color: transparent;
    border: none;
    outline: none;
    padding-left: 20px;
    padding-top: 8px;
    color: white;
}
.searchForm input::placeholder{
    color: white;
}
.userChat{
    display: flex;
    align-items: center;
    /* justify-content: center; */
    gap: 10px;
    padding-left: 10px;
    /* padding-top: 8px; */
}
.userChat:hover{
    background-color: rgb(96, 56, 190);
}
.userChat img{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    object-fit: cover;
}
.userInfo{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
}
.userInfo span{
    color: white;
    margin-top: 5px;
}

.userInfo p{
    margin-top: -5px;
    padding-left: 5px;
}


/* chat component */
.chatInfo{
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: rgba(90, 10, 165, 0.774);
    border-bottom:  1px solid rgb(227, 61, 61);
}
 .chatInfo span{
    padding-left: 15px;
    font-size: 20px;
    font-weight: bold;
 }

.chatInfo .chatIcons {
    display: flex;
    align-items: center;
     gap: 5px;
     padding-right: 15px;
}
.chatIcons img{
     width: 20px;
     height: 20px;
}
.messages{
    background-color: rgba(195, 219, 238, 0.826);
    height: calc(100% - 150px );
    /* overflow:scroll; */
    overflow-y: scroll;
    padding: 10px;
}
/* message form   */
    .message{
        /* width: 100%; */
        display: flex;     
        margin-bottom: 15px;
        gap: 30px;
 }

 .messageInfo {
    display: flex;
    flex-direction: column;
 }
.messageInfo img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.messageInfo span{
    font-size: 14px;
    color: rgb(119, 119, 119);
    padding-left: 10px;
}
.message .messageContent{
    max-width: 80%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
 .messageContent img{
    width: 50%;height: 100%;

}
.messageContent p{
    background-color: white;
    padding: 10px 20px;
    border-radius: 0 8px 8px 8px;
    max-width: max-content;
}
.owner{
    flex-direction: row-reverse ;
}
.owner .messageContent{
    align-items: flex-end;
}
.owner p{
    background-color: rgba(9, 9, 177, 0.732);
    color: white;
    border-radius: 8px 0 8px 8px;
}
.input{
    background-color: rgb(192, 192, 192);
    padding: 10px 0;
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.send{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.input input{
    width: 80%;
    padding: 10px 0 10px 10px;
    border: none;
    outline: none;
    margin-left: 7px;
 
    
}
.input .send .ss{
position: absolute;
    margin-right: 310px;
    
}
.send .attach{
     margin-left: 60px;
     margin-right: 20px;
}
.input .send .btn{

background-color: blueviolet;
margin-left:15px ;
    
}

@media only screen and (max-width: 600px) {
    .home{
        width: 100vw;
        height: 101vh;
        margin: 0;
        padding: 0;
        margin-left: -.5rem;

    }
    .container{
        width: 100vw;
        height: 100%;
    }
    .sidebar{
        flex: 3;
    }
    .navbar .logo{
        display: none;
    }
    .input input{
        width: 60%;
    }
    .input .send button{

            margin-right: -120px;
            
        }
  }